@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@300;400&display=swap');


/*@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Patrick+Hand&display=swap" );




html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a{
  color: #ca96b1;
  text-decoration: none;
}

a:visited{
  color: #ca96b1;

}

a:hover{
  text-decoration: underline;
  color: #916581;
}


html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}
textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
}
button {
  color: #3992ff;
  font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

.pfp{
  margin-top: 1rem;
  object-fit: contain;
  width: 100%;
  max-width: 12rem;
  border-radius: 2px;
  border: solid white 8px;
  border-bottom: solid white 16px;
  filter: drop-shadow(0 0 .25rem black);

}


#root {
  display: flex;
  height: 100%;
  width: 100%;
}


#link{
  font-family: Spectral, serif;
  text-transform: lowercase;
  font-size:26px;
  padding: 0;
  margin-left: 1rem;
}

#link a{
  color: black;
  opacity: 75%;
}
#link a:hover{
  opacity: 100%;
}

#sidebar li {
  display: flex;
  margin: 0.25rem 0;
  width: 100%;
  font-weight: bold;
  color: dimgrey;
}


#sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  white-space: pre;
  padding: 0.5rem;
  border-radius: 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: #e3e3e3;
}

#sidebar nav a.active {
  background: hsl(224, 98%, 58%);
  color: white;
}

#sidebar nav a.pending {
  color: hsl(224, 98%, 58%);
}

#detail {
  flex: 1;
  padding: 2rem 0;
  width: 100%;
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

#contact {
  max-width: 40rem;
  display: flex;
}

#contact h1 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

#contact h1 + p {
  margin: 0;
}

#contact h1 + p + p {
  white-space: break-spaces;
}

#contact h1:focus {
  outline: none;
  color: hsl(224, 98%, 58%);
}

#contact a[href*="twitter"] {
  display: flex;
  font-size: 1.5rem;
  color: #3992ff;
  text-decoration: none;
}
#contact a[href*="twitter"]:hover {
  text-decoration: underline;
}

#contact img {
  width: 12rem;
  height: 12rem;
  background: #c8c8c8;
  margin-right: 2rem;
  border-radius: 1.5rem;
  object-fit: cover;
}

#contact h1 ~ div {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
}

#contact-form {
  display: flex;
  max-width: 40rem;
  flex-direction: column;
  gap: 1rem;
}
#contact-form > p:first-child {
  margin: 0;
  padding: 0;
}
#contact-form > p:first-child > :nth-child(2) {
  margin-right: 1rem;
}
#contact-form > p:first-child,
#contact-form label {
  display: flex;
}
#contact-form p:first-child span,
#contact-form label span {
  width: 8rem;
}
#contact-form p:first-child input,
#contact-form label input,
#contact-form label textarea {
  flex-grow: 2;
}

#contact-form-avatar {
  margin-right: 2rem;
}

#contact-form-avatar img {
  width: 12rem;
  height: 12rem;
  background: hsla(0, 0%, 0%, 0.2);
  border-radius: 1rem;
}

#contact-form-avatar input {
  box-sizing: border-box;
  width: 100%;
}

#contact-form p:last-child {
  display: flex;
  gap: 0.5rem;
  margin: 0 0 0 8rem;
}

#contact-form p:last-child button[type="button"] {
  color: inherit;
}

#zero-state {
  margin: 2rem auto;
  text-align: center;
  color: #818181;
}

#zero-state a {
  color: inherit;
}

#zero-state a:hover {
  color: #121212;
}

#zero-state:before {
  display: block;
  margin-bottom: 0.5rem;
  content: url("data:image/svg+xml,%3Csvg width='50' height='33' viewBox='0 0 50 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.8262 11.1744C37.3975 10.7252 36.6597 10.8766 35.1611 10.7128C32.8444 10.4602 31.9215 9.55475 31.5299 7.22456C31.3108 5.92377 31.5695 4.01923 31.0102 2.8401C29.9404 0.591789 27.4373 -0.416556 24.9225 0.158973C22.7992 0.645599 21.0326 2.76757 20.9347 4.94569C20.8228 7.43263 22.2399 9.5546 24.6731 10.2869C25.8291 10.6355 27.0574 10.8109 28.2646 10.8998C30.4788 11.0613 30.6489 12.292 31.2479 13.3051C31.6255 13.9438 31.9914 14.5731 31.9914 16.4775C31.9914 18.3819 31.6231 19.0112 31.2479 19.6499C30.6489 20.6606 29.9101 21.3227 27.696 21.4865C26.4887 21.5754 25.2581 21.7508 24.1044 22.0994C21.6712 22.834 20.2542 24.9537 20.366 27.4406C20.4639 29.6187 22.2306 31.7407 24.3538 32.2273C26.8686 32.8052 29.3717 31.7945 30.4415 29.5462C31.0032 28.3671 31.3108 27.0312 31.5299 25.7304C31.9238 23.4002 32.8467 22.4948 35.1611 22.2421C36.6597 22.0784 38.2107 22.2421 39.615 21.4443C41.099 20.36 42.4248 18.7328 42.4248 16.4775C42.4248 14.2222 40.9961 11.8575 38.8262 11.1744Z' fill='%23E3E3E3'/%3E%3Cpath d='M15.1991 21.6854C12.2523 21.6854 9.84863 19.303 9.84863 16.3823C9.84863 13.4615 12.2523 11.0791 15.1991 11.0791C18.1459 11.0791 20.5497 13.4615 20.5497 16.3823C20.5497 19.3006 18.1436 21.6854 15.1991 21.6854Z' fill='%23E3E3E3'/%3E%3Cpath d='M5.28442 32.3871C2.36841 32.38 -0.00698992 29.9882 1.54551e-05 27.0652C0.00705187 24.1469 2.39884 21.7715 5.32187 21.7808C8.24022 21.7878 10.6156 24.1796 10.6063 27.1027C10.5992 30.0187 8.20746 32.3941 5.28442 32.3871Z' fill='%23E3E3E3'/%3E%3Cpath d='M44.736 32.387C41.8107 32.4033 39.4096 30.0373 39.3932 27.1237C39.3769 24.1984 41.7428 21.7973 44.6564 21.7808C47.5817 21.7645 49.9828 24.1305 49.9993 27.0441C50.0156 29.9671 47.6496 32.3705 44.736 32.387Z' fill='%23E3E3E3'/%3E%3C/svg%3E%0A");
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

#code h2 {
  margin: 0;


}


#code p {
  margin: 0;
  padding:0;
  font-size: 14px;
}

.tag{
    margin: 0.5rem;
    font-weight: bold;
    color: white;
    background-color: #eeb004;
    padding-right: .5rem;
    padding-left: .5rem;
    border-radius: .7rem;
    height: 100%;
}

.art-card{
  flex-grow: 1;
  opacity: 0;
  padding: 1rem;
  color: white;
  height: 100%;
}

.art-card:hover{
  opacity: 75%;
  background-color: black;
}

.art-img{
  width: 100%;
}


div.gallery img {
  width: 100%;
  height: auto;
  padding: 0;

}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  position: relative;
  width: 100%;
  height: 18rem;
  display: flex;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;


}

.overlay {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  font-family: Spectral, serif;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0,0,0,.75);
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  align-self: flex-end;
  text-align: left;
}

.article {
  margin-bottom: 4rem;
}

.article a{
  color: grey;

}

.article a:hover {
  color: black;
}

#art h1{
  font-size: 100px;
  font-family:  Patrick Hand, cursive;
  line-height: 1;
  color: black;
  margin-bottom: 0;
  /*-webkit-text-stroke: 1px black;*/
}

#art h2{
  background-color: #fff2d2;
  font-family:  Patrick Hand, cursive;
}

#art h3{
  /*font-size: 20px;*/
  margin:0;
  font-family:  Patrick Hand, cursive;
  font-size: 32px;
}

#art p{
  font-size: 20px;
  font-family: Spectral, serif;
  margin: .3rem;
}

#art a:hover{
  text-decoration: underline;
}

.prog-icon{

  height: 2rem;
  text-align: center;
  align-self: center;
  margin: 0 .2rem 0 0;
}

.projects{
  padding: .5rem;


}

.projects div{
  padding: .5rem;

}
.art-div div{
  padding: 0;
}

.projects ul{
  width: max-content;
}

.projects li {

  font-family: Spectral, serif;
  font-size: 20px;
  margin-top: 1rem;
  text-align: center;
  padding: .5rem;
  list-style-type: none;
  transition: 0.3s;
  border-radius: 2px;

  background-color: rgba(255, 216, 237, 0.47);
}

.project-link{
  font-family: Spectral, serif;
  font-size: 20px;
  text-align: center;
  padding: .5rem;
  list-style-type: none;
  transition: 0.3s;
  border-radius: 2px;



  background-color: rgba(255, 216, 237, 0.47);

}

.project-link:hover{
  background-color: #FFD8ED;
  filter: drop-shadow(10px 10px 4px rgba(116, 78, 78, 0.71));

  transform: translateY(-5px);
}


@media screen and (max-width: 1180px) {
  .project-header{
    display: inline;
    justify-content: center;

  }
  .project-desc{
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1180px) {
  .project-header{
    display: flex;
  }
  .project-desc{
    margin-left: 5rem;
    align-self: center;
  }

}

.projects a{
  color: black;
  opacity: 75%;
  text-decoration: none;

}

.projects a:hover {
  opacity: 100%;


}

.projects ul{

  margin: 0;
  padding: 0;
}

.projects-panel{
  height: 100%;
  padding: 0;
  font-size: 16px;
  font-family:  Spectral, serif;

}

.content-panel{

margin-right: 8rem;
  font-size: 22px;

}



.content-panel h1{
  border-left: gray 4px solid;
  /*font-style: italic;*/
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-family:  Spectral, serif;
}

.content-panel h4{
  text-transform: uppercase;
  font-style: normal;
  padding-left: 2rem;
  color: #818181;
  margin-bottom: 3rem;
  font-family:  Spectral, serif;
}

.content-panel h3{
  font-size: 30px;
  font-family:  Spectral, serif;

}

.content-panel h5{
  font-family: "Spectral", serif;
  font-size: 18px;
  margin: 0;
  text-align: right;
  font-weight: normal;
  font-style: italic;


  color: #7d7d7d;


}


.content-panel p{
  font-family: Spectral, serif;
}


.content-panel li{
  background-color: #e7e7e7;
  width: 100%;
}

.content-panel li:hover{
  background-color: #f6f5f5;;
}

.swiper {
  width: 600px;
  height: 300px;
}

.tools{
margin-left: 2rem;
}

.tools li{
  margin: initial;
  text-align: left;

  transition: initial;
  border-radius: initial;
  background-color: initial;
  list-style-type: initial;
}

.tools li:hover{
  background-color: initial;

  transform: initial;
}
.tools ul{
  margin-left: 1rem;
}

.constraints li{
  margin: initial;
  text-align: left;
  transition: initial;
  border-radius: initial;
  background-color: initial;
  list-style-type: decimal;
}

.constraints li:hover{
  background-color: initial;

  transform: initial;
}

.cover-img img{
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
}

.blueprint-img img{
  width:900px;
  height: 300px;
  object-fit: contain;
}

.code-img img{
  width:100%;
  height: 800px;
  object-fit: scale-down;
}

.contain-img img{
  width:600px;
  height: 300px;
  object-fit: cover;
}

.end-article{
  padding: 0;
  border: none;
  text-align: center;
  color: darkred;
  font-style: italic;
}

#other h1{
  font-family:  Spectral, serif;
  font-size: 40px;
}

#other h2{
  font-family:  Spectral, serif;
  margin: 1rem;
  background-color:#FFEFBC;
}

#other h3{
  font-family:  Spectral, serif;
}

#other p{
  font-family: Spectral, serif;
  font-size: 20px;
}





code{
  background-color: #e7e7e7;
  color: #299ff9;
  border-radius: 4px;
  padding: 4px;
  font-size: 18px;

}
